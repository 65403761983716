import {
  ref, computed, onMounted, onBeforeUnmount,
} from '@vue/composition-api'

export default {
  name: 'Console',
  props: {
    automatic: Boolean,
    rectangle: {
      type: Boolean,
      default: true,
    },
    text: String,
    value: undefined,
    input: Boolean,
    inputFull: Boolean,
    disabled: Boolean,
    mask: {
      type: String,
      default: '99999',
    },
    leftArrowSvg: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const textProxy = ref('')
    const inputRefNum = ref(null)
    const inputRefFull = ref(null)

    const position = ref(0)

    const valueProxy = computed({
      get: () => props.value,
      set: (value) => {
        if (value.length > 50) {
          return
        }
        emit('input', value)
      },
    })

    const inputValue = (data, num) => {
      let i = num
      setTimeout(() => {
        if (data.length <= i) {
          emit('finish')
          return
        }
        textProxy.value += data[i]
        i += 1
        inputValue(data, i)
      }, 4)
    }

    const init = () => {
      if (props.automatic) inputValue(props.text, 0)
    }

    init()

    const getWidthInput = computed(() => (valueProxy.value.length ? valueProxy.value.length + 0.5 : 1))

    window.addEventListener('keydown', () => {
      if (inputRefNum.value) {
        inputRefNum.value.focus()
      }
      if (inputRefFull.value) {
        inputRefFull.value.focus()
      }
    })

    let interval = null

    onMounted(() => {
      if (props.inputFull) {
        interval = setInterval(() => {
          position.value = inputRefFull.value.selectionStart
        }, 50)
      } else if (props.input) {
        interval = setInterval(() => {
          position.value = inputRefNum.value.selectionStart
        }, 50)
      }
    })

    onBeforeUnmount(() => {
      clearInterval(interval)
    })

    const enterNum = (clear) => {
      if (props.disabled) return

      emit('enter')
      if (clear) {
        valueProxy.value = ''
      }
    }

    return {
      valueProxy,
      textProxy,
      getWidthInput,
      inputRefNum,
      inputRefFull,
      position,
      enterNum,
    }
  },
}
