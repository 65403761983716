var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({class:[
    _vm.$style.button,
    _vm.icon && _vm.$style.icon,
    _vm.white && _vm.$style.white,
    _vm.blue && _vm.$style.blue,
    _vm.red && _vm.$style.red,
    _vm.mint && _vm.$style.mint,
    _vm.outlined && _vm.$style.outlined,
    _vm.lg && _vm.$style.lg ]},_vm.$listeners),[_vm._t("default"),_c('div',{domProps:{"innerHTML":_vm._s(_vm.icon ? _vm.getSvg(_vm.icon).value : '')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }