import { computed } from '@vue/composition-api'

import { svgArr } from './svg'

export default {
  name: 'PopupFile',
  props: {
    title: String,
    svg: String,
    right: Boolean,
  },
  setup(props) {
    const getSvg = computed(() => svgArr.filter(e => e.url === props.svg)[0].svg)
    const getSvgRight = val => computed(() => svgArr.filter(e => e.url === val)[0].svg)
    return {
      getSvg,
      getSvgRight,
    }
  },
}
