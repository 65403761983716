import { ref, watch } from '@vue/composition-api'

export default {
  name: 'containerWindowCheck',
  props: {
    items: Array,
    programs: {
      type: Array,
      default: () => ([]),
    },
    keyName: String,
    switcher: Boolean,
    line: Boolean,
  },
  setup(props, { emit }) {
    const itemsProxy = ref(props.items)
    const programsProxy = ref(props.programs)
    const switcherActive = ref(false)

    const update = () => emit('getObj', { checkBox: itemsProxy.value, program: programsProxy.value, switcher: switcherActive.value })

    watch([switcherActive, programsProxy, itemsProxy], update, { deep: true })

    update()

    return {
      itemsProxy,
      programsProxy,
      switcherActive,
    }
  },
}
