export default {
  name: 'capsule',
  components: {},
  props: {
    items: Array,
  },
  setup() {
    return {}
  },
}
