export default {
  name: 'tooltip',
  props: {
    text: String,
  },
  setup() {
    return {
    }
  },
}
