import Emoji from '@/components/@ui/emoji'

export default {
  name: '',
  components: {
    Emoji,
  },
  props: {
    items: Array,
    consoleText: Boolean,
  },
  setup() {
    return {}
  },
}
