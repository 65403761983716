import Vue from 'vue'

import lSlider from '@/components/@ui/slider'
import lPopupMessage from '@/components/@ui/popupMessage'
import lButton from '@/components/@ui/button'
import lConsole from '@/components/@ui/console'
import lContainer from '@/components/@ui/container'
import lLoading from '@/components/@ui/loading'
import lFiles from '@/components/@ui/files/default'
import lFilesRow from '@/components/@ui/files/components/row'
import lBgComics from '@/components/@ui/bgComics'
import lPopupFile from '@/components/@ui/popupFile'
import lListWindow from '@/components/@ui/listWindow'
import lLoadingWindow from '@/components/@ui/loadingWindow'
import lContainerWindow from '@/components/@ui/containerWindow'
import lContainerMessage from '@/components/@ui/containerMessage'
import lAvatar from '@/components/@ui/avatar'
import lContainerMessageForm from '@/components/@ui/containerMessageForm'
import lPopupWindow from '@/components/@ui/popupWindow'
import lContainerLessonConsole from '@/components/@ui/containerLessonConsole'
import lContainerLessonConsoleTo from '@/components/@ui/containerLessonConsoleTo'
import lConsoleNum from '@/components/@ui/consoleNum'
import lContainerWindowCheck from '@/components/@ui/containerWindowCheck'
import lTooltip from '@/components/@ui/tooltip'
import lToggleSwitch from '@/components/@ui/ToggleSwitch'
import lCheckbox from '@/components/@ui/checkbox'
import lContainerDesktopToRight from '@/components/@ui/containerDesktopToRight'
import lMessageMobile from '@/components/@ui/messageMobile'

Vue.component('l-slider', lSlider)
Vue.component('l-popupMessage', lPopupMessage)
Vue.component('l-button', lButton)
Vue.component('l-console', lConsole)
Vue.component('l-container', lContainer)
Vue.component('l-loading', lLoading)
Vue.component('l-files', lFiles)
Vue.component('l-files-row', lFilesRow)
Vue.component('l-bg-comics', lBgComics)
Vue.component('l-popup-file', lPopupFile)
Vue.component('l-list-window', lListWindow)
Vue.component('l-loading-window', lLoadingWindow)
Vue.component('l-container-window', lContainerWindow)
Vue.component('l-container-message', lContainerMessage)
Vue.component('l-avatar', lAvatar)
Vue.component('l-container-message-form', lContainerMessageForm)
Vue.component('l-popup-window', lPopupWindow)
Vue.component('l-container-lesson-console', lContainerLessonConsole)
Vue.component('l-container-lesson-console-to', lContainerLessonConsoleTo)
Vue.component('l-console-num', lConsoleNum)
Vue.component('l-container-window-check', lContainerWindowCheck)
Vue.component('l-tooltip', lTooltip)
Vue.component('l-toggle-switch', lToggleSwitch)
Vue.component('l-checkbox', lCheckbox)
Vue.component('l-container-desktop-to-right', lContainerDesktopToRight)
Vue.component('l-message-mobile', lMessageMobile)
