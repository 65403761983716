import { ref } from '@vue/composition-api'

export default {
  name: 'Loading',
  props: {
    value: Boolean,
    virusNum: [Number, String],
    title: String,
    keyName: String,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const precent = ref(0)

    const startLoading = () => {
      setTimeout(() => {
        if (precent.value === 100) {
          emit('finish', { keyName: props.keyName })
          return
        }
        precent.value += 1
        startLoading()
      }, 4)
    }

    const init = () => {
      startLoading()
    }

    init()

    return {
      precent,
    }
  },
}
