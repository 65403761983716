import { computed } from '@vue/composition-api'
import { svgArr } from './svg/arrs'

export default {
  name: 'Button',
  props: {
    transparent: Boolean,
    white: Boolean,
    blue: Boolean,
    red: Boolean,
    mint: Boolean,
    outlined: Boolean,
    icon: undefined,
    lg: Boolean,
  },
  setup() {
    const getSvg = val => computed(() => svgArr.filter(e => e.url === val)[0].svg)
    return {
      getSvg,
    }
  },
}
