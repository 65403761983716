import {
  ref, watch, onMounted, onBeforeUnmount,
} from '@vue/composition-api'

export default {
  name: 'Container',
  props: {
    window: Boolean,
    scroll: Boolean,
    scrollTop: Number,
    noPadding: Boolean,
    hidden: Boolean,
  },
  setup(props) {
    const scrollRef = ref(null)

    watch(() => props.scrollTop, () => {
      scrollRef.value.scroll(0, props.scrollTop)
    })

    onMounted(() => {
      scrollRef.value.scrollTo({
        top: scrollRef.value.scrollHeight,
        behavior: 'smooth',
      })
    })

    if (MutationObserver) {
      let observer = null
      onMounted(() => {
        const callback = () => scrollRef.value.scrollTo(0, scrollRef.value.scrollHeight)
        observer = new MutationObserver(callback)

        observer.observe(scrollRef.value, { childList: true, subtree: true, attributes: false })
      })

      onBeforeUnmount(() => {
        observer.disconnect()
      })
    }

    return {
      scrollRef,
    }
  },
}
