import { ref } from '@vue/composition-api'

export default {
  name: 'containerMessage',
  components: {},
  props: {
    items: Array,
    disabled: Boolean,
  },
  setup(props) {
    const currentItem = ref(props.items[0])

    return {
      currentItem,
    }
  },
}
