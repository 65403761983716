/* eslint-disable camelcase */
import axios from 'axios'
import qs from 'query-string'

const instance = axios.create()

const URL = process.env.NODE_ENV === 'production' ? 'https://xn--h1adlhdnlo2c.xn--p1ai/' : 'https://stage.datalesson.ru/'

const post = (url, data) => instance.post(`${URL}trainer/${url}`, data)

const TRAINER_BY_URL = {
  education1: 'junior',
  education2: 'middle',
  education3: 'senior',
}

export const useWebhook = () => {
  let timer = null
  let timeSpent = 0
  let session_id = null
  let trainerName = 'junior'

  const init = () => {
    const parsed = qs.parse(window.location.search)
    // eslint-disable-next-line prefer-destructuring
    session_id = parsed.session_id
  }

  const startTimer = () => {
    timer = setInterval(() => {
      timeSpent += 1
    }, 1000)
  }

  const stopTimer = () => {
    clearInterval(timer)
    timer = null
  }

  const setTrainerName = (href) => {
    const arr = href.split('/')
    trainerName = TRAINER_BY_URL[arr[1]] ?? 'junior'
  }

  const reqHook = ({ score = 3 } = {}) => post('webhook', {
    score,
    time_spent: timeSpent,
    session_id,
    trainerName,
  }) // возвращает объект с ссылкой link, ее нужно открывать по кнопке в хабе

  const reqMarkFirstTask = ({ firstTask = 1 } = {}) => post('markFirstTask', {
    session_id,
    firstTask,
  })

  init()

  return {
    startTimer,
    stopTimer,
    setTrainerName,

    reqMarkFirstTask,
    reqHook,
  }
}

export default useWebhook()
