import { ref } from '@vue/composition-api'

export default {
  name: 'Loading',
  props: {
    value: Boolean,
    virusNum: [Number, String],
    precentVal: {
      type: Number,
      default: 0,
    },
    en: Boolean,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const precent = ref(props.precentVal)

    const startLoading = () => {
      setTimeout(() => {
        if (precent.value === 100) {
          emit('finish')
          return
        }
        precent.value += 1
        startLoading()
      }, 10)
    }

    const init = () => {
      startLoading()
    }

    init()

    return {
      precent,
    }
  },
}
