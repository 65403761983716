import Capsule from './components/capsule'

export default {
  name: 'containerWindow',
  props: {
    capsuleList: Array,
    shield: Boolean,
    disabled: Boolean,
  },
  components: {
    Capsule,
  },
  setup() {
    return {}
  },
}
