var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.folder,
    _vm.err && _vm.$style.err,
    _vm.activeClick && _vm.err && _vm.$style.emittingClick,
    _vm.activeCursor && _vm.err && _vm.$style.emittingCursor,
    _vm.mRB && _vm.$style.mRB,
    _vm.black && _vm.$style.black,
    _vm.fzXs && _vm.$style.fzXs,
    _vm.font === 'coisine' && _vm.$style.fontCoisine,
    _vm.font === 'kaspersky' && _vm.$style.fontKaspersky
  ],style:({ paddingTop: (_vm.pt + "rem"), paddingBottom: (_vm.pb + "rem") }),on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.folder)?[_c('img',{attrs:{"src":require("./img/folder.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.file)?[_c('img',{attrs:{"src":require("./img/file.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.mailService)?[_c('img',{attrs:{"src":require("./img/mailService.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.securityScanner)?[_c('img',{attrs:{"src":require("./img/securityScanner.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.cloud)?[_c('div',{class:_vm.$style.test},[_c('img',{attrs:{"src":require("./img/cloud.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])])]:_vm._e(),(_vm.office)?[_c('img',{attrs:{"src":require("./img/office.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.antivirus)?[_c('img',{attrs:{"src":require("./img/antivirus.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.pdf)?[_c('img',{attrs:{"src":require("./img/pdf.png")}}),_c('div',[_vm._v(" "+_vm._s(_vm.title)+" ")])]:_vm._e(),(_vm.img)?_c('div',{class:_vm.$style.folderInner},[(_vm.getSvg)?_c('div',{class:_vm.$style.svg,domProps:{"innerHTML":_vm._s(_vm.getSvg)}}):_c('img',{attrs:{"src":require(("./img/" + _vm.img + ".png"))}}),_c('div',[_vm._v(_vm._s(_vm.title))]),(_vm.currentNum)?_c('div',{class:_vm.$style.currentNum},[_vm._v(_vm._s(_vm.currentNum))]):_vm._e()]):_vm._e(),(_vm.err && _vm.emittingClick)?_c('div',{class:_vm.$style.cursor}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }