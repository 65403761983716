<template>
  <div :class="$style.wrapper">
    <slot />
    <dummy />
  </div>
</template>
<script>

import dummy from './components/MobilePortraitDummy'

export default {
  name: 'DefaultLayout',
  components: {
    dummy,
  },
}
</script>
<style lang="scss" module src="./DefaultLayout.scss"></style>
