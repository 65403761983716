import Vue from 'vue'
import FlowAdaptive from 'flow-adaptive-vue'

Vue.use(FlowAdaptive, {
  fontRatio: 1440 / 10,
  maxWidth: 1440,
  // minWidth: 650,
  // breakPoints: [
  //   {
  //     minWidth: 650,
  //     maxWidth: 1024,
  //     fontRatio: 768 / 10,
  //   },
  //   {
  //     maxWidth: 649,
  //     fontRatio: 320 / 10,
  //   },
  // ],
})
