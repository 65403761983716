import { ref } from '@vue/composition-api'

export default {
  name: '',
  props: {
    items: undefined,
    item: undefined,
    index: Number,
    lastIndex: Number,
    lengthDisabled: Number,
  },
  setup() {
    const tooltip = ref(false)

    return {
      tooltip,
    }
  },
}
