import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/education1',
  },
  {
    path: '/education1',
    meta: { layout: 'DefaultLayout' },
    component: () => import('../views/Education1'),
    children: [
      {
        path: '/',
        redirect: 'lesson1',
      },
      {
        path: 'lesson1',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education1/Lesson1'),
      },
      {
        path: 'lesson2',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education1/Lesson2'),
      },
      {
        path: 'lesson3',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education1/Lesson3'),
      },
      {
        path: 'lesson4',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education1/Lesson4'),
      },
    ],
  },
  {
    path: '/education2',
    meta: { layout: 'DefaultLayout' },
    component: () => import('../views/Education2'),
    children: [
      {
        path: '/',
        redirect: 'lesson1',
      },
      {
        path: 'lesson1',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education2/Lesson1'),
      },
      {
        path: 'lesson2',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education2/Lesson2'),
      },
      {
        path: 'lesson3',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education2/Lesson3'),
      },
      {
        path: 'lesson4',
        meta: { layout: 'DefaultLayout' },
        component: () => import('../views/Education2/Lesson4'),
      },
    ],
  },
  {
    path: '/education3',
    meta: { layout: 'DefaultLayout' },
    component: () => import('../views/Education3'),
    children: [
      {
        path: '/',
        redirect: 'lesson1',
      },
      {
        path: 'lesson1',
        component: () => import('../views/Education3/Lesson1'),
      },
      {
        path: 'lesson2',
        component: () => import('../views/Education3/Lesson2'),
      },
      {
        path: 'lesson3',
        component: () => import('../views/Education3/Lesson3'),
      },
      {
        path: 'lesson4',
        component: () => import('../views/Education3/Lesson4'),
      },
    ],
  },
  {
    path: '/hub',
    meta: { layout: 'DefaultLayout' },
    component: () => import('../views/Hub'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
