import { watch, ref } from '@vue/composition-api'

export default {
  name: 'containerLessonConsole',
  props: {
    value: Boolean,
    bg: String,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const wrapperMode = ref(false)
    const wrapperModeClose = ref(false)

    const nextStep = () => {
      emit('active', true)
      wrapperMode.value = true
      setTimeout(() => {
        wrapperModeClose.value = true
      }, 1000)
      setTimeout(() => {
        emit('nextStep')
      }, 1400)
    }

    watch(() => props.value, () => {
      if (props.value) nextStep()
    })

    return {
      wrapperMode,
      wrapperModeClose,
      nextStep,
    }
  },
}
