import { computed } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: undefined,
    },
  },
  setup(props, { emit }) {
    const check = computed({
      get: () => props.value,
      set: val => emit('input', val),
    })
    return {
      check,
    }
  },
}
