export default {
  name: 'Files',
  props: {
    items: Array,
    folder: Boolean,
    file: Boolean,
    errIndex: [String, Number],
    emittingClick: Boolean,
    img: String,
  },
  setup() {
    return {
    }
  },
}
