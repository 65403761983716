<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
    <PortalTarget name="popup" multiple></PortalTarget>
    <l-message-mobile/>
  </div>
</template>
<script>
import DefaultLayout from '@/components/@layout/DefaultLayout/DefaultLayout'

import webhook from '@/webhook'

export default {
  name: 'App',
  metaInfo: {
    title: 'Главная',
    titleTemplate: 'Урок Цифры | Безопасность',
  },
  components: {
    DefaultLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout'
    },
  },
  mounted() {
    webhook.setTrainerName(window.location.pathname)
    webhook.startTimer()
  },
}
</script>
<style lang="scss">
  @import './styles/main.scss';
</style>
