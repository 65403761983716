// import { ref } from '@vue/composition-api'

export default {
  name: 'avatar',
  props: {
    text: String,
  },
  setup() {
    return {
    }
  },
}
