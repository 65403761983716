import { ref, onMounted, onBeforeUnmount } from '@vue/composition-api'

import clamp from 'lodash/clamp'

export default {
  name: 'Slider',
  props: {
    items: Array,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const current = ref(0)

    const finish = () => emit('finish')

    const next = () => {
      // if (current.value === props.items.length - 1) {
      //   finish()
      //   return
      // }
      current.value = clamp(current.value + 1, 0, props.items.length - 1)
    }

    const prev = () => {
      current.value = clamp(current.value - 1, 0, props.items.length - 1)
    }

    const onKeyDown = (e) => {
      if (e.keyCode === 37) prev()
      if (e.keyCode === 39) next()
    }

    onMounted(() => {
      document.addEventListener('keydown', onKeyDown)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onKeyDown)
    })

    return {
      current,
      next,
      prev,
      finish,
    }
  },
}
