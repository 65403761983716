import { computed, ref } from '@vue/composition-api'

import { arrSvg } from './svg'

export default {
  name: 'Files',
  props: {
    folder: Boolean,
    file: Boolean,
    securityScanner: Boolean,
    mailService: Boolean,
    cloud: Boolean,
    office: Boolean,
    antivirus: Boolean,
    pdf: Boolean,
    title: String,
    err: Boolean,
    emittingClick: Boolean,
    mRB: Boolean,
    black: Boolean,
    pt: [String, Number],
    pb: [String, Number],
    fzXs: Boolean,
    currentNum: [String, Number],
    img: String,
    font: {
      type: String,
      default: 'cousine',
    },

  },
  setup(props, ctx) {
    const { emit } = ctx
    const activeClick = ref(false)
    const activeCursor = ref(false)

    const init = () => {
      if (props.emittingClick) {
        setTimeout(() => {
          activeCursor.value = true
        }, 1000)
        setTimeout(() => {
          activeClick.value = true
        }, 2000)
        setTimeout(() => {
          emit('finish')
        }, 3000)
      }
    }
    init()

    const getSvg = computed(() => arrSvg.filter(e => e.url === props.img)[0].svg ?? 'svg')

    return {
      activeClick,
      activeCursor,
      getSvg,
    }
  },
}
