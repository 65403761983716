import { computed } from '@vue/composition-api'
import { svgArr } from './svg/arrs'

export default {
  name: 'list',
  props: {
    items: Array,
    disabled: Boolean,
  },

  setup() {
    const getSvg = val => computed(() => svgArr.filter(e => e.url === val)[0].svg)
    return {
      getSvg,
    }
  },
}
