// import { computed } from '@vue/composition-api'

export default {
  name: 'containerMessageForm',
  components: {},
  props: {
    backgroundImage: String,
    title: String,
    center: Boolean,
    red: Boolean,
    mint: Boolean,
    backgroundBody: String,
    backgroundWrapper: String,
  },
  setup() {
    return {}
  },
}
