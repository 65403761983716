import {
  computed, ref, onMounted, onBeforeUnmount,
} from '@vue/composition-api'

import { svgArr } from './svg'

import Item from './components/item'

import currentIndex from './z-index'

export default {
  name: 'PopupFile',
  components: {
    Item,
  },
  props: {
    title: String,
    svg: String,
    right: Boolean,
    inner: Boolean,
    gray: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    let isFocus = false
    const getSvg = computed(() => svgArr.filter(e => e.url === props?.svg)[0]?.svg ?? '')
    const getSvgRight = val => computed(() => svgArr.filter(e => e.url === val)[0]?.svg)
    const dragHeader = ref(null)
    const dragContainer = ref(null)

    const zIndex = ref(currentIndex.zIndex + 1)

    const pos = ref({
      x: 0,
      y: 0,
    })

    const shift = ref({
      x: 0,
      y: 0,
    })

    const moveAt = (x, y) => {
      const { width, height } = dragContainer.value.getBoundingClientRect()

      const vh = window.innerHeight
      const vw = window.innerWidth

      const newX = x - shift.value.x
      const newY = y - shift.value.y

      pos.value.x = newX + width <= vw ? newX : vw - width
      pos.value.y = newY + height <= vh ? newY : vh - height

      if (pos.value.x < 0) pos.value.x = 0
      if (pos.value.y < 0) pos.value.y = 0
    }

    const onMouseMove = (event) => {
      if (!isFocus) return

      moveAt(event.pageX, event.pageY)
    }

    const onTouchMove = (event) => {
      if (!isFocus) return

      moveAt(event.touches[0].clientX, event.touches[0].clientY)
    }

    const onMouseDown = (e) => {
      shift.value.x = e.clientX - dragContainer.value.getBoundingClientRect().left
      shift.value.y = e.clientY - dragContainer.value.getBoundingClientRect().top

      isFocus = true

      document.body.style.userSelect = 'none'
    }

    const onTouchDown = (e) => {
      shift.value.x = e.touches[0].clientX - dragContainer.value.getBoundingClientRect().left
      shift.value.y = e.touches[0].clientY - dragContainer.value.getBoundingClientRect().top

      isFocus = true

      document.body.style.userSelect = 'none'
    }

    const onMouseUp = () => {
      isFocus = false

      document.body.style.userSelect = ''
    }

    const updateZIndex = () => {
      currentIndex.zIndex += 1

      zIndex.value = currentIndex.zIndex
    }

    const init = () => {
      const { width, height } = dragContainer.value.getBoundingClientRect()
      const wh = window.innerHeight
      const ww = window.innerWidth
      pos.value.x = ww / 2 - width / 2
      pos.value.y = wh / 2 - height / 2
    }

    onMounted(() => {
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
      dragHeader.value.addEventListener('mousedown', onMouseDown)
      dragContainer.value.addEventListener('mousedown', updateZIndex)

      document.addEventListener('touchmove', onTouchMove)
      dragHeader.value.addEventListener('touchstart', onTouchDown)
      dragHeader.value.addEventListener('touchend', onMouseUp)
      init()

      if (MutationObserver) {
        let observer = null
        onMounted(() => {
          const callback = () => {
            init()
          }
          observer = new MutationObserver(callback)

          observer.observe(dragContainer.value, { childList: true, subtree: true, attributes: false })
        })
      }
    })

    onBeforeUnmount(() => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      dragHeader.value.removeEventListener('mousedown', onMouseDown)
      dragContainer.value.removeEventListener('mousedown', updateZIndex)

      document.removeEventListener('touchmove', onTouchMove)
      dragHeader.value.removeEventListener('touchstart', onTouchDown)
      dragHeader.value.removeEventListener('touchend', onMouseUp)
    })

    return {
      getSvg,
      dragHeader,
      dragContainer,
      pos,
      zIndex,
      getSvgRight,
    }
  },
}
