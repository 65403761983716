import { ref, onMounted } from '@vue/composition-api'

const img = [
  { url: require('@/assets/lesson1/1.png') },
]

export default {
  name: 'step1',
  props: {
    popup: Array,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const active = ref(true)
    const popups = ref(props.popup)

    const goToRight = ref(false)
    const goNextPage = ref(false)
    // const platform = navigator.platform.toLowerCase()

    const changeWrapperMode = () => {
      const timeGoNextPage = 2000
      const timeNextStep = 2200

      goToRight.value = true
      popups.value = []
      // if (platform === 'win32' || platform === 'win64') {
      //   timeGoNextPage = 6000
      //   timeNextStep = 6200
      // }
      setTimeout(() => {
        goNextPage.value = true
      }, timeGoNextPage)
      setTimeout(() => {
        emit('nextStep', 2)
      }, timeNextStep)
    }

    const nextStep = () => changeWrapperMode()

    const init = () => {
      const time = 1000
      // if (platform === 'win32' || platform === 'win64') time = 5000
      if (!popups.value) {
        setTimeout(() => {
          nextStep()
        }, time)
      }
    }

    onMounted(() => init())

    return {
      img,
      active,
      popups,
      goToRight,
      goNextPage,
      changeWrapperMode,
      nextStep,
    }
  },
}
