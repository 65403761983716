<template>
  <label :class="$style.switch">
    <input type="checkbox" v-model="switcher">
    <span :class="[$style.slider, $style.round]"></span>
  </label>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: undefined,
    },
  },
  setup(props, { emit }) {
    const switcher = computed({
      set: (val) => {
        emit('input', val)
      },
      get: () => props.value,
    })
    return {
      switcher,
    }
  },
}
</script>

<style scoped lang="scss" module>
.switch {
  position: relative;
  display: inline-block;
  width: 6.0rem;
  height: 3.4rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.6rem;
  width: 2.6rem;
  left: .4rem;
  bottom: .4rem;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4BDE1D;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(2.6rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
