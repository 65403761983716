export default {
  name: 'PopupWindow',

  props: {
    title: String,
    value: Boolean,
  },
  setup() {
    return {}
  },
}
